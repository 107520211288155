<script setup>
import {router, usePage} from "@inertiajs/vue3";
import {onMounted} from "vue";

// console.log(import.meta.env)

const clientId = import.meta.env.VITE_YANDEX_CLIENT_ID ?? '';
const isAuthenticated = usePage().props.auth?.user ?? false
onMounted(() => {
    if (isAuthenticated) {
        return;
    }
    if (!clientId) {
        console.warn('Yandex One Tap is disabled. Please provide VITE_YANDEX_CLIENT_ID in .env file');
        return;
    }
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://yastatic.net/s3/passport-sdk/autofill/v1/sdk-suggest-with-polyfills-latest.js')
    recaptchaScript.onload = () => {
        // console.log('Yandex One Tap is loaded')
        // console.log('Callback url', route('oauth.callback', {provider: 'yandex'}, true))
        window.YaAuthSuggest.init(
            {
                client_id: clientId,
                response_type: 'code',
                redirect_uri: route('yandex-one-tap', true),
            },
            import.meta.env.VITE_APP_URL ?? ''
        )
            .then(resp => {
                // console.log('Yandex One Tap response', resp)
                return resp.handler()
            })
            .then(data => {
                // console.log('Yandex One Tap data', data)
                // console.log('Yandex One Tap extra data', data.extraData)
                router.get(route('oauth.callback', {provider: 'yandex', state: false}, true), {
                    code: data.code
                })
            })
            .catch(error => console.log('Yandex One tap error', error));
    }
    document.head.appendChild(recaptchaScript)
});
</script>

<template>

</template>
